*,
::after,
::before {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-image: url('./assets/background.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

h3 {
    color: #000000;
}

a {
    cursor: pointer;
    text-decoration: none;
}

li {
    list-style: none;
}

/* Layout skeleton */

.wrapper {
    align-items: stretch;
    display: flex;
    width: 100%;
}

#sidebar {
    max-width: 264px;
    min-width: 264px;
    transition: all 0.35s ease-in-out;
    box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
    z-index: 1111;
}

/* Sidebar collapse */

#sidebar.collapsed {
    margin-left: -264px;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
}

.sidebar-logo {
    padding: 2rem 2rem;
}

.sidebar-logo a {
    display: inline-block;
    width: 180px; /* Define o tamanho do círculo */
    height: 125px; /* Define o tamanho do círculo */
    background-image: url('./assets/logo.png');

}

.sidebar-nav {
    padding: 0;
}

.sidebar-header {
    color: #000000;
    font-size: .75rem;
    padding: 1.5rem 1.5rem .375rem;
}

a.sidebar-link {
    padding: .625rem 1.625rem;
    color: #000000;
    position: relative;
    display: block;
    font-size: 1rem;
}

.sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
    
}

.sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}


#sidebar .sidebar-link {
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  color: #000000;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

#sidebar .sidebar-link:hover {
  background-color: #000000; /* Cor de fundo no hover */
  color: #ffffff; /* Cor do texto no hover */

}




.content {
    flex: 1;
    max-width: 100vw;
    width: 100vw;
}

/* Responsive */

@media (min-width:768px) {
    .content {
        width: auto;
    }
}

/* Estilos para os itens do sidebar */
.sidebar-item {
    width: 100%;
    list-style: none;
}

.sidebar-link {
    display: block;
    width: 100%;
    padding: 1rem 1.5rem;
    color: #000000;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar-link:hover {
    background-color: #000000; /* Cor de fundo no hover */
    color: #ffffff; /* Cor do texto no hover */
}

.sidebar-link i {
    margin-right: 0.5rem;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  display: none;
}

.page-item {
  &.active .page-link {
    background-color: #000;
    border-color: #fff;
    color: #ffffff
  }

  .page-link {
    background-color: #6C757D;
    border-color: #fff;
    color: #ffffff;
  }
}

.custom-switch .form-check-input {
  width: 60px !important; /* Define a largura do input */
  height: 30px; /* Define a altura do input */
}

.auto-suggest-container {
  position: relative;
}

.auto-suggest-list {
  position: absolute;
  top: 100%; /* Posiciona abaixo do input */
  left: 0;
  right: 0;
  z-index: 1000; /* Garante que fique acima de outros elementos */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Trocar a cor azul claro dos compos para preto e branco */
input:focus,
select:focus,
textarea:focus {
  border-color: #000000 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important; 
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(235, 235, 235) inset !important;
  -webkit-text-fill-color: black !important;
  background-color: rgb(235, 235, 235) !important; /* Cor de fundo cinza claro */
}

.login-logo {
  margin-top: 20px;
  margin-bottom: 0;
}

.login-logo img {
  width: 180px;
  height: 125px;
}

.login-modal {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.login-form {
  margin-left:10px;
  margin-right:10px;
}

@media (max-width: 768px) {
  .login-modal .modal-dialog {
    margin: 20px;
  }
}